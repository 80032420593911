import React from "react";
import Chevron from "../../assets/images/common/chevron-right.svg";
import { Link } from "react-router-dom";
import "../../assets/css/blooddonation.scss";
const BreadCrum = ({
  firstText,
  firstLink,
  secondLink,
  secondText,
  thirdText,
}) => {
  return (
    <p className="blooddonation-breadcrumb">
      <Link to="/">
        <span>Dashboard</span>
      </Link>
      {firstLink && (
        <>
          <img src={Chevron} alt="" />
          <Link to={firstLink}>
            {/* <span className={!secondText && "current-tab"}> {firstText}</span> */}
            <span className={!secondText && "current-tab"}> {firstText}</span>
          </Link>
        </>
      )}
      {secondText && (
        <>
          <img src={Chevron} alt="" />
          <Link to={secondLink}>
            <span
              className={!thirdText && "current-tab"}
              style={{ height: "100px" }}
            >
              {secondText}
            </span>
          </Link>
        </>
      )}
      {thirdText && (
        <>
          <img src={Chevron} alt="" />
          {/* <Link to={thirdLink}>
          </Link> */}
          <span className="current-tab" style={{ height: "100px" }}>
            {thirdText}
          </span>
        </>
      )}
    </p>
  );
};

export default BreadCrum;
