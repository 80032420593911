import { listSubheaderClasses } from "@mui/material";
import { checkSvg, clockSvg, locationSvg, profileSvg } from "../assets/icons";

export const allPostData = [
  {
    post_id: 1,
    author: "user1",
    university: "Harvard",
    comments: 20,
    likes: 50,
    content: "Studying hard for exams!",
    timestamp: "2023-11-28T10:15:00Z",
    profile: "https://picsum.photos/200/300?random=1",
  },
  {
    post_id: 2,
    author: "user2",
    university: "MIT",
    comments: 15,
    likes: 35,
    content: "Excited about the upcoming project!",
    timestamp: "2023-11-28T11:30:00Z",
    profile: "https://picsum.photos/200/300?random=1",
  },
  {
    post_id: 3,
    author: "user3",
    university: "Stanford",
    comments: 25,
    likes: 42,
    content: "Exploring campus life!",
    timestamp: "2023-11-28T12:45:00Z",
    profile: "https://picsum.photos/200/300?random=1",
  },
  {
    post_id: 4,
    author: "user4",
    university: "Oxford",
    comments: 18,
    likes: 55,
    content: "Attending a fascinating lecture today.",
    timestamp: "2023-11-28T14:00:00Z",
    profile: "https://picsum.photos/200/300?random=1",
  },
  {
    post_id: 5,
    author: "user5",
    university: "Cambridge",
    comments: 22,
    likes: 48,
    content: "Weekend getaway!",
    timestamp: "2023-11-28T15:15:00Z",
    profile: "https://picsum.photos/200/300?random=1",
  },
  {
    post_id: 6,
    author: "user6",
    university: "UC Berkeley",
    comments: 30,
    likes: 60,
    content: "Coding all night!",
    timestamp: "2023-11-28T16:30:00Z",
    profile: "https://picsum.photos/200/300?random=1",
  },
  {
    post_id: 7,
    author: "user7",
    university: "Princeton",
    comments: 12,
    likes: 40,
    content: "Researching for my thesis.",
    timestamp: "2023-11-28T17:45:00Z",
    profile: "https://picsum.photos/200/300?random=1",
  },
  {
    post_id: 8,
    author: "user8",
    university: "Caltech",
    comments: 28,
    likes: 50,
    content: "Science fair success!",
    timestamp: "2023-11-28T19:00:00Z",
    profile: "https://picsum.photos/200/300?random=1",
  },
  {
    post_id: 9,
    author: "user9",
    university: "Columbia",
    comments: 17,
    likes: 38,
    content: "Preparing for a presentation.",
    timestamp: "2023-11-28T20:15:00Z",
    profile: "https://picsum.photos/200/300?random=1",
  },
  {
    post_id: 10,
    author: "user10",
    university: "ETH Zurich",
    comments: 23,
    likes: 45,
    content: "International conference vibes!",
    timestamp: "2023-11-28T21:30:00Z",
    profile: "https://picsum.photos/200/300?random=1g",
  },
];

export const boostedPost = [
  {
    post_id: 1,
    author: "user1",
    university: "Harvard",
    comments: 20,
    likes: 50,
    content: "Studying hard for exams!",
    timestamp: "2023-11-28T10:15:00Z",
    profile: "https://picsum.photos/200/300?random=1",
    paymentDetails: {
      dues: 30,
      paidAmount: 20,
      pendingAmount: 10,
      totalAmount: 30,
    },
  },
  {
    post_id: 2,
    author: "user2",
    university: "MIT",
    comments: 15,
    likes: 35,
    content: "Excited about the upcoming project!",
    timestamp: "2023-11-28T11:30:00Z",
    profile: "https://picsum.photos/200/300?random=1",
    paymentDetails: {
      dues: 25,
      paidAmount: 15,
      pendingAmount: 10,
      totalAmount: 25,
    },
  },
  {
    post_id: 3,
    author: "user3",
    university: "Stanford",
    comments: 25,
    likes: 42,
    content: "Exploring campus life!",
    timestamp: "2023-11-28T12:45:00Z",
    profile: "https://picsum.photos/200/300?random=1",
    paymentDetails: {
      dues: 40,
      paidAmount: 15,
      pendingAmount: 25,
      totalAmount: 40,
    },
  },
  {
    post_id: 4,
    author: "user4",
    university: "Oxford",
    comments: 18,
    likes: 55,
    content: "Attending a fascinating lecture today.",
    timestamp: "2023-11-28T14:00:00Z",
    profile: "https://picsum.photos/200/300?random=1",
    paymentDetails: {
      dues: 55,
      paidAmount: 30,
      pendingAmount: 25,
      totalAmount: 55,
    },
  },
  {
    post_id: 5,
    author: "user5",
    university: "Cambridge",
    comments: 22,
    likes: 48,
    content: "Weekend getaway!",
    timestamp: "2023-11-28T15:15:00Z",
    profile: "https://picsum.photos/200/300?random=1",
    paymentDetails: {
      dues: 48,
      paidAmount: 26,
      pendingAmount: 22,
      totalAmount: 48,
    },
  },
  {
    post_id: 6,
    author: "user6",
    university: "UC Berkeley",
    comments: 30,
    likes: 60,
    content: "Coding all night!",
    timestamp: "2023-11-28T16:30:00Z",
    profile: "https://picsum.photos/200/300?random=1",
    paymentDetails: {
      dues: 60,
      paidAmount: 30,
      pendingAmount: 30,
      totalAmount: 60,
    },
  },
  {
    post_id: 7,
    author: "user7",
    university: "Princeton",
    comments: 12,
    likes: 40,
    content: "Researching for my thesis.",
    timestamp: "2023-11-28T17:45:00Z",
    profile: "https://picsum.photos/200/300?random=1",
    paymentDetails: {
      dues: 40,
      paidAmount: 20,
      pendingAmount: 20,
      totalAmount: 40,
    },
  },
  {
    post_id: 8,
    author: "user8",
    university: "Caltech",
    comments: 28,
    likes: 50,
    content: "Science fair success!",
    timestamp: "2023-11-28T19:00:00Z",
    profile: "https://picsum.photos/200/300?random=1",
    paymentDetails: {
      dues: 50,
      paidAmount: 30,
      pendingAmount: 20,
      totalAmount: 50,
    },
  },
  {
    post_id: 9,
    author: "user9",
    university: "Columbia",
    comments: 17,
    likes: 38,
    content: "Preparing for a presentation.",
    timestamp: "2023-11-28T20:15:00Z",
    profile: "https://picsum.photos/200/300?random=1",
    paymentDetails: {
      dues: 38,
      paidAmount: 20,
      pendingAmount: 18,
      totalAmount: 38,
    },
  },
  {
    post_id: 10,
    author: "user10",
    university: "ETH Zurich",
    comments: 23,
    likes: 45,
    content: "International conference vibes!",
    timestamp: "2023-11-28T21:30:00Z",
    profile: "https://picsum.photos/200/300?random=1g",
    paymentDetails: {
      dues: 45,
      paidAmount: 25,
      pendingAmount: 20,
      totalAmount: 45,
    },
  },
];

export const allEventData = [
  {
    event_id: 1,
    name: "Tech Symposium 2023",
    university: "Harvard",
    organizer: [
      {
        organizer_id: 1,
        name: "John Doe",
        position: "Professor of Computer Science",
        email: "john.doe@harvard.edu",
      },
      {
        organizer_id: 2,
        name: "Jane Smith",
        position: "Assistant Professor of Engineering",
        email: "jane.smith@harvard.edu",
      },
      {
        organizer_id: 3,
        name: "Robert Johnson",
        position: "Research Scientist",
        email: "robert.johnson@harvard.edu",
      },
    ],
    date: "2023-12-10",
    location: "Harvard Auditorium",
    attendees: 300,
    description:
      "Explore the latest advancements in technology at Tech Symposium 2023 hosted by Harvard University.",
    image: "https://picsum.photos/800/400?random=1",
  },
  {
    event_id: 2,
    name: "Science Fair Expo",
    university: "MIT",
    organizer: [
      {
        organizer_id: 4,
        name: "Emily Davis",
        position: "Professor of Physics",
        email: "emily.davis@mit.edu",
      },
      {
        organizer_id: 5,
        name: "Michael Brown",
        position: "Associate Professor of Chemistry",
        email: "michael.brown@mit.edu",
      },
      {
        organizer_id: 6,
        name: "Sophie Wilson",
        position: "Researcher",
        email: "sophie.wilson@mit.edu",
      },
    ],
    date: "2023-12-15",
    location: "MIT Campus",
    attendees: 200,
    description:
      "Join us at the Science Fair Expo to witness innovative projects and experiments by MIT students and researchers.",
    image: "https://picsum.photos/800/400?random=2",
  },
  {
    event_id: 3,
    name: "Stanford Business Conference",
    university: "Stanford",
    organizer: [
      {
        organizer_id: 7,
        name: "David White",
        position: "Professor of Business Administration",
        email: "david.white@stanford.edu",
      },
      {
        organizer_id: 8,
        name: "Olivia Robinson",
        position: "Assistant Professor of Economics",
        email: "olivia.robinson@stanford.edu",
      },
      {
        organizer_id: 9,
        name: "Daniel Taylor",
        position: "Entrepreneur-in-Residence",
        email: "daniel.taylor@stanford.edu",
      },
    ],
    date: "2023-12-20",
    location: "Stanford Business School",
    attendees: 400,
    description:
      "Participate in discussions on the latest trends in business and entrepreneurship at the Stanford Business Conference.",
    image: "https://picsum.photos/800/400?random=3",
  },
  {
    event_id: 4,
    name: "Oxford Literary Festival",
    university: "Oxford",
    organizer: [
      {
        organizer_id: 10,
        name: "Emma Thompson",
        position: "Professor of Literature",
        email: "emma.thompson@ox.ac.uk",
      },
      {
        organizer_id: 11,
        name: "James Miller",
        position: "Associate Professor of English",
        email: "james.miller@ox.ac.uk",
      },
      {
        organizer_id: 12,
        name: "Sophia Turner",
        position: "Literary Critic",
        email: "sophia.turner@ox.ac.uk",
      },
    ],
    date: "2023-12-25",
    location: "Oxford Town Hall",
    attendees: 150,
    description:
      "Immerse yourself in a celebration of literature and art at the Oxford Literary Festival.",
    image: "https://picsum.photos/800/400?random=4",
  },
  {
    event_id: 5,
    name: "Cambridge Music Showcase",
    university: "Cambridge",
    organizer: [
      {
        organizer_id: 13,
        name: "Andrew Wilson",
        position: "Professor of Music",
        email: "andrew.wilson@cam.ac.uk",
      },
      {
        organizer_id: 14,
        name: "Grace Martin",
        position: "Music Composer",
        email: "grace.martin@cam.ac.uk",
      },
      {
        organizer_id: 15,
        name: "Thomas Green",
        position: "Conductor",
        email: "thomas.green@cam.ac.uk",
      },
    ],
    date: "2023-12-30",
    location: "Cambridge Concert Hall",
    attendees: 250,
    description:
      "Enjoy an evening of musical performances by talented artists at the Cambridge Music Showcase.",
    image: "https://picsum.photos/800/400?random=5",
  },
  {
    event_id: 6,
    name: "Berkeley Tech Summit",
    university: "UC Berkeley",
    organizer: [
      {
        organizer_id: 16,
        name: "Jennifer Lee",
        position: "Professor of Computer Science",
        email: "jennifer.lee@berkeley.edu",
      },
      {
        organizer_id: 17,
        name: "Michael Davis",
        position: "Chief Technology Officer",
        email: "michael.davis@berkeley.edu",
      },
      {
        organizer_id: 18,
        name: "Elena Rodriguez",
        position: "Tech Entrepreneur",
        email: "elena.rodriguez@berkeley.edu",
      },
    ],
    date: "2024-01-05",
    location: "Berkeley Convention Center",
    attendees: 350,
    description:
      "Engage with industry experts and explore the future of technology at the Berkeley Tech Summit.",
    image: "https://picsum.photos/800/400?random=6",
  },
  {
    event_id: 7,
    name: "Princeton Research Symposium",
    university: "Princeton",
    organizer: [
      {
        organizer_id: 19,
        name: "Christopher Turner",
        position: "Professor of Physics",
        email: "christopher.turner@princeton.edu",
      },
      {
        organizer_id: 20,
        name: "Jessica Brown",
        position: "Research Scientist",
        email: "jessica.brown@princeton.edu",
      },
      {
        organizer_id: 21,
        name: "Brian Wilson",
        position: "Assistant Professor of Chemistry",
        email: "brian.wilson@princeton.edu",
      },
    ],
    date: "2024-01-10",
    location: "Princeton Research Center",
    attendees: 180,
    description:
      "Discover groundbreaking research at the Princeton Research Symposium, featuring presentations from top scholars.",
    image: "https://picsum.photos/800/400?random=7",
  },
  {
    event_id: 8,
    name: "Caltech Space Exploration Expo",
    university: "Caltech",
    organizer: [
      {
        organizer_id: 22,
        name: "Laura Mitchell",
        position: "Astrophysicist",
        email: "laura.mitchell@caltech.edu",
      },
      {
        organizer_id: 23,
        name: "Kevin Turner",
        position: "Space Engineer",
        email: "kevin.turner@caltech.edu",
      },
      {
        organizer_id: 24,
        name: "Catherine White",
        position: "Planetary Scientist",
        email: "catherine.white@caltech.edu",
      },
    ],
    date: "2024-01-15",
    location: "Caltech Space Research Center",
    attendees: 300,
    description:
      "Embark on a journey through space exploration at the Caltech Space Exploration Expo.",
    image: "https://picsum.photos/800/400?random=8",
  },
  {
    event_id: 9,
    name: "Columbia Art Exhibition",
    university: "Columbia",
    organizer: [
      {
        organizer_id: 25,
        name: "Isabella Martin",
        position: "Professor of Fine Arts",
        email: "isabella.martin@columbia.edu",
      },
      {
        organizer_id: 26,
        name: "Charles Turner",
        position: "Art Curator",
        email: "charles.turner@columbia.edu",
      },
      {
        organizer_id: 27,
        name: "Eva Davis",
        position: "Gallery Director",
        email: "eva.davis@columbia.edu",
      },
    ],
    date: "2024-01-20",
    location: "Columbia Art Gallery",
    attendees: 200,
    description:
      "Immerse yourself in the world of art at the Columbia Art Exhibition showcasing diverse forms of artistic expression.",
    image: "https://picsum.photos/800/400?random=9",
  },
  {
    event_id: 10,
    name: "ETH Zurich Robotics Challenge",
    university: "ETH Zurich",
    organizer: [
      {
        organizer_id: 28,
        name: "Laura White",
        position: "Professor of Robotics",
        email: "laura.white@ethz.ch",
      },
      {
        organizer_id: 29,
        name: "Alex Rodriguez",
        position: "Researcher",
        email: "alex.rodriguez@ethz.ch",
      },
      {
        organizer_id: 30,
        name: "Sophie Miller",
        position: "Ph.D. Candidate",
        email: "sophie.miller@ethz.ch",
      },
    ],
    date: "2024-01-25",
    location: "ETH Zurich Robotics Lab",
    attendees: 250,
    description:
      "Witness the latest advancements in robotics at the ETH Zurich Robotics Challenge.",
    image: "https://picsum.photos/800/400?random=10",
  },
];

export const boostedEventData = [
  {
    event_id: 1,
    name: "Tech Symposium 2023",
    university: "Harvard",
    organizers: [
      {
        organizer_id: 1,
        name: "John Doe",
        email: "john.doe@example.com",
        phone: "+1 123-456-7890",
      },
      {
        organizer_id: 2,
        name: "Jane Smith",
        email: "jane.smith@example.com",
        phone: "+1 987-654-3210",
      },
    ],
    date: "2023-12-10",
    location: "Harvard Auditorium",
    attendees: 300,
    description:
      "Explore the latest advancements in technology at Tech Symposium 2023 hosted by Harvard University.",
    image: "https://picsum.photos/800/400?random=1",
    due: "2023-12-10",
    pending_due: 200,
    paid_amount: 100,
  },
  {
    event_id: 2,
    name: "Science Fair Expo",
    university: "MIT",
    organizers: [
      {
        organizer_id: 3,
        name: "Robert Johnson",
        email: "robert.johnson@example.com",
        phone: "+1 345-678-9012",
      },
      {
        organizer_id: 4,
        name: "Emma Davis",
        email: "emma.davis@example.com",
        phone: "+1 567-890-1234",
      },
    ],
    date: "2023-12-15",
    location: "MIT Campus",
    attendees: 200,
    description:
      "Join us at the Science Fair Expo to witness innovative projects and experiments by MIT students and researchers.",
    image: "https://picsum.photos/800/400?random=2",
    due: "2023-12-15",
    pending_due: 150,
    paid_amount: 50,
  },
  {
    event_id: 3,
    name: "Stanford Business Conference",
    university: "Stanford",
    organizers: [
      {
        organizer_id: 5,
        name: "Michael Brown",
        email: "michael.brown@example.com",
        phone: "+1 789-012-3456",
      },
      {
        organizer_id: 6,
        name: "Sophia White",
        email: "sophia.white@example.com",
        phone: "+1 012-345-6789",
      },
    ],
    date: "2023-12-20",
    location: "Stanford Business School",
    attendees: 400,
    description:
      "Participate in discussions on the latest trends in business and entrepreneurship at the Stanford Business Conference.",
    image: "https://picsum.photos/800/400?random=3",
    due: "2023-12-20",
    pending_due: 300,
    paid_amount: 100,
  },
  {
    event_id: 4,
    name: "Oxford Literary Festival",
    university: "Oxford",
    organizers: [
      {
        organizer_id: 7,
        name: "David Wilson",
        email: "david.wilson@example.com",
        phone: "+1 234-567-8901",
      },
      {
        organizer_id: 8,
        name: "Olivia Smith",
        email: "olivia.smith@example.com",
        phone: "+1 456-789-0123",
      },
    ],
    date: "2023-12-25",
    location: "Oxford Town Hall",
    attendees: 150,
    description:
      "Immerse yourself in a celebration of literature and art at the Oxford Literary Festival.",
    image: "https://picsum.photos/800/400?random=4",
    due: "2023-12-25",
    pending_due: 100,
    paid_amount: 50,
  },
  {
    event_id: 5,
    name: "Cambridge Music Showcase",
    university: "Cambridge",
    organizers: [
      {
        organizer_id: 9,
        name: "Emily Davis",
        email: "emily.davis@example.com",
        phone: "+1 567-890-1234",
      },
      {
        organizer_id: 10,
        name: "Daniel Brown",
        email: "daniel.brown@example.com",
        phone: "+1 789-012-3456",
      },
    ],
    date: "2023-12-30",
    location: "Cambridge Concert Hall",
    attendees: 250,
    description:
      "Enjoy an evening of musical performances by talented artists at the Cambridge Music Showcase.",
    image: "https://picsum.photos/800/400?random=5",
    due: "2023-12-30",
    pending_due: 200,
    paid_amount: 50,
  },
  {
    event_id: 6,
    name: "Berkeley Tech Summit",
    university: "UC Berkeley",
    organizers: [
      {
        organizer_id: 11,
        name: "Alex Johnson",
        email: "alex.johnson@example.com",
        phone: "+1 012-345-6789",
      },
      {
        organizer_id: 12,
        name: "Mia White",
        email: "mia.white@example.com",
        phone: "+1 234-567-8901",
      },
    ],
    date: "2024-01-05",
    location: "Berkeley Convention Center",
    attendees: 350,
    description:
      "Engage with industry experts and explore the future of technology at the Berkeley Tech Summit.",
    image: "https://picsum.photos/800/400?random=6",
    due: "2024-01-05",
    pending_due: 250,
    paid_amount: 100,
  },
  {
    event_id: 7,
    name: "Princeton Research Symposium",
    university: "Princeton",
    organizers: [
      {
        organizer_id: 13,
        name: "Sophie Davis",
        email: "sophie.davis@example.com",
        phone: "+1 456-789-0123",
      },
      {
        organizer_id: 14,
        name: "William Johnson",
        email: "william.johnson@example.com",
        phone: "+1 678-901-2345",
      },
    ],
    date: "2024-01-10",
    location: "Princeton Research Center",
    attendees: 180,
    description:
      "Discover groundbreaking research at the Princeton Research Symposium, featuring presentations from top scholars.",
    image: "https://picsum.photos/800/400?random=7",
    due: "2024-01-10",
    pending_due: 120,
    paid_amount: 60,
  },
  {
    event_id: 8,
    name: "Caltech Space Exploration Expo",
    university: "Caltech",
    organizers: [
      {
        organizer_id: 15,
        name: "Lucas Smith",
        email: "lucas.smith@example.com",
        phone: "+1 789-012-3456",
      },
      {
        organizer_id: 16,
        name: "Ella Brown",
        email: "ella.brown@example.com",
        phone: "+1 901-234-5678",
      },
    ],
    date: "2024-01-15",
    location: "Caltech Space Research Center",
    attendees: 300,
    description:
      "Embark on a journey through space exploration at the Caltech Space Exploration Expo.",
    image: "https://picsum.photos/800/400?random=8",
    due: "2024-01-15",
    pending_due: 200,
    paid_amount: 100,
  },
  {
    event_id: 9,
    name: "Columbia Art Exhibition",
    university: "Columbia",
    organizers: [
      {
        organizer_id: 17,
        name: "Oliver Davis",
        email: "oliver.davis@example.com",
        phone: "+1 901-234-5678",
      },
      {
        organizer_id: 18,
        name: "Ava Johnson",
        email: "ava.johnson@example.com",
        phone: "+1 234-567-8901",
      },
    ],
    date: "2024-01-20",
    location: "Columbia Art Gallery",
    attendees: 200,
    description:
      "Immerse yourself in the world of art at the Columbia Art Exhibition showcasing diverse forms of artistic expression.",
    image: "https://picsum.photos/800/400?random=9",
    due: "2024-01-20",
    paid_amount: 100,
  },
];

export const officialCommunityData = [
  {
    name: "Tech Enthusiasts",
    ranking: 1,
    followers: 5000,
    University: "Global",
    hosting_members: ["John Doe", "Jane Smith", "Alice Johnson"],
    Members: 10000,
    image: "https://picsum.photos/800/400?random=11",
    likes: 56450,
    banner: "https://picsum.photos/1200/300?random=41",
    bio: "Welcome to the Tech Enthusiasts community! We are a global community of technology enthusiasts who love to explore and discuss the latest trends in the tech world.",
  },
  {
    name: "Data Science Wizards",
    ranking: 3,
    followers: 3000,
    University: "MIT",
    hosting_members: ["David Brown", "Emily White", "Charlie Green"],
    Members: 5000,
    image: "https://picsum.photos/800/400?random=12",
    likes: 52340,
    banner: "https://picsum.photos/1200/300?random=42",
    bio: "Data Science Wizards is a community of data enthusiasts affiliated with MIT. Join us to explore the fascinating world of data science and its applications.",
  },
  {
    name: "Entrepreneurship Hub",
    ranking: 2,
    followers: 4000,
    University: "Stanford",
    hosting_members: ["Michael Lee", "Sophie Davis", "Ryan Turner"],
    Members: 8000,
    image: "https://picsum.photos/800/400?random=13",
    likes: 5780,
    banner: "https://picsum.photos/1200/300?random=43",
    bio: "Welcome to the Entrepreneurship Hub at Stanford! We are a vibrant community of entrepreneurs, innovators, and visionaries shaping the future of business.",
  },
  {
    name: "Artists Collective",
    ranking: 4,
    followers: 2000,
    University: "Columbia",
    hosting_members: ["Olivia Perez", "Daniel Kim", "Ella Davis"],
    Members: 3000,
    image: "https://picsum.photos/800/400?random=14",
    likes: 89050,
    banner: "https://picsum.photos/1200/300?random=44",
    bio: "The Artists Collective at Columbia is a creative haven for artists to share their work, collaborate, and inspire. Join us to express and appreciate art!",
  },
  {
    name: "Robotics Innovators",
    ranking: 5,
    followers: 1500,
    University: "ETH Zurich",
    hosting_members: ["Alex Johnson", "Mia Brown", "Luke Turner"],
    Members: 2500,
    image: "https://picsum.photos/800/400?random=15",
    likes: 55640,
    banner: "https://picsum.photos/1200/300?random=45",
    bio: "Robotics Innovators at ETH Zurich is a community of robotics enthusiasts pushing the boundaries of innovation in robotics and automation. Join us on the cutting edge!",
  },
  {
    name: "Code Warriors",
    ranking: 6,
    followers: 1200,
    University: "Harvard",
    hosting_members: ["Sarah White", "Jake Davis", "Sophia Turner"],
    Members: 2000,
    image: "https://picsum.photos/800/400?random=16",
    likes: 5350,
    banner: "https://picsum.photos/1200/300?random=46",
    bio: "Code Warriors at Harvard is a dynamic community of coding enthusiasts dedicated to solving challenging problems and advancing the world of coding.",
  },
  {
    name: "Design Mavericks",
    ranking: 7,
    followers: 1000,
    University: "RISD",
    hosting_members: ["Ava Perez", "Liam Kim", "Eva Brown"],
    Members: 1500,
    image: "https://picsum.photos/800/400?random=17",
    likes: 5430,
    banner: "https://picsum.photos/1200/300?random=47",
    bio: "Welcome to Design Mavericks at RISD! We are a community of creative minds exploring innovative and groundbreaking designs. Join us to unleash your creativity!",
  },
  {
    name: "Blockchain Pioneers",
    ranking: 8,
    followers: 800,
    University: "UC Berkeley",
    hosting_members: ["Nathan Turner", "Emma White", "Lucas Davis"],
    Members: 1200,
    image: "https://picsum.photos/800/400?random=18",
    likes: 6750,
    banner: "https://picsum.photos/1200/300?random=48",
    bio: "Blockchain Pioneers at UC Berkeley is your gateway to the world of blockchain technology. Join us to explore and pioneer the future of decentralized solutions!",
  },
  {
    name: "Health Tech Innovators",
    ranking: 9,
    followers: 600,
    University: "Johns Hopkins",
    hosting_members: ["Zoe Brown", "Isaac Kim", "Lily Perez"],
    Members: 900,
    image: "https://picsum.photos/800/400?random=19",
    likes: 5560,
    banner: "https://picsum.photos/1200/300?random=49",
    bio: "Health Tech Innovators at Johns Hopkins is committed to advancing healthcare through technology. Join us to be part of the innovation in health tech!",
  },
  {
    name: "Green Energy Advocates",
    ranking: 10,
    followers: 500,
    University: "ETH Zurich",
    hosting_members: ["Sophie Turner", "Mason White", "Grace Davis"],
    Members: 700,
    image: "https://picsum.photos/800/400?random=20",
    likes: 500,
    banner: "https://picsum.photos/1200/300?random=50",
    bio: "Green Energy Advocates at ETH Zurich is a community passionate about sustainable and green energy solutions. Join us to promote and advocate for a greener future!",
  },
];

export const customCommunityData = [
  {
    name: "Tech Enthusiasts",
    ranking: 1,
    followers: 5000,
    hosting_members: ["John Doe", "Jane Smith", "Alice Johnson"],
    Members: 10000,
    Creator: "John Doe",
    Membership: "Premium",
    image: "https://picsum.photos/800/400?random=21",
    banner: "https://picsum.photos/1200/300?random=31",
    bio: "Welcome to the Tech Enthusiasts community! We are passionate about exploring the latest in technology and sharing our knowledge with fellow members.",
  },
  {
    name: "Data Science Wizards",
    ranking: 3,
    followers: 3000,
    hosting_members: ["David Brown", "Emily White", "Charlie Green"],
    Members: 5000,
    Creator: "David Brown",
    Membership: "Standard",
    image: "https://picsum.photos/800/400?random=22",
    banner: "https://picsum.photos/1200/300?random=32",
    bio: "Data Science Wizards is the place where data enthusiasts come together to explore the magic of data science. Join us on this exciting journey!",
  },
  {
    name: "Entrepreneurship Hub",
    ranking: 2,
    followers: 4000,
    hosting_members: ["Michael Lee", "Sophie Davis", "Ryan Turner"],
    Members: 8000,
    Creator: "Michael Lee",
    Membership: "Premium",
    image: "https://picsum.photos/800/400?random=23",
    banner: "https://picsum.photos/1200/300?random=33",
    bio: "Welcome to the Entrepreneurship Hub! This is the go-to place for aspiring and established entrepreneurs to connect, collaborate, and grow together.",
  },
  {
    name: "Artists Collective",
    ranking: 4,
    followers: 2000,
    hosting_members: ["Olivia Perez", "Daniel Kim", "Ella Davis"],
    Members: 3000,
    Creator: "Olivia Perez",
    Membership: "Standard",
    image: "https://picsum.photos/800/400?random=24",
    banner: "https://picsum.photos/1200/300?random=34",
    bio: "The Artists Collective is a space for creative minds to share and appreciate art in all its forms. Join us to celebrate artistic expression!",
  },
  {
    name: "Robotics Innovators",
    ranking: 5,
    followers: 1500,
    hosting_members: ["Alex Johnson", "Mia Brown", "Luke Turner"],
    Members: 2500,
    Creator: "Alex Johnson",
    Membership: "Premium",
    image: "https://picsum.photos/800/400?random=25",
    banner: "https://picsum.photos/1200/300?random=35",
    bio: "Robotics Innovators is the community where robotics enthusiasts come together to explore the latest advancements in the world of robotics and automation.",
  },
  {
    name: "Code Warriors",
    ranking: 6,
    followers: 1200,
    hosting_members: ["Sarah White", "Jake Davis", "Sophia Turner"],
    Members: 2000,
    Creator: "Sarah White",
    Membership: "Standard",
    image: "https://picsum.photos/800/400?random=26",
    banner: "https://picsum.photos/1200/300?random=36",
    bio: "Code Warriors is a community of coding enthusiasts who love to tackle challenging problems and push the boundaries of what's possible with code.",
  },
  {
    name: "Design Mavericks",
    ranking: 7,
    followers: 1000,
    hosting_members: ["Ava Perez", "Liam Kim", "Eva Brown"],
    Members: 1500,
    Creator: "Ava Perez",
    Membership: "Premium",
    image: "https://picsum.photos/800/400?random=27",
    banner: "https://picsum.photos/1200/300?random=37",
    bio: "Design Mavericks is the place where creative minds converge to explore innovative and groundbreaking designs. Join us on this design journey!",
  },
  {
    name: "Blockchain Pioneers",
    ranking: 8,
    followers: 800,
    hosting_members: ["Nathan Turner", "Emma White", "Lucas Davis"],
    Members: 1200,
    Creator: "Nathan Turner",
    Membership: "Standard",
    image: "https://picsum.photos/800/400?random=28",
    banner: "https://picsum.photos/1200/300?random=38",
    bio: "Welcome to Blockchain Pioneers! We explore the latest trends and advancements in blockchain technology. Join us to be part of the blockchain revolution!",
  },
  {
    name: "Health Tech Innovators",
    ranking: 9,
    followers: 600,
    hosting_members: ["Zoe Brown", "Isaac Kim", "Lily Perez"],
    Members: 900,
    Creator: "Zoe Brown",
    Membership: "Premium",
    image: "https://picsum.photos/800/400?random=29",
    banner: "https://picsum.photos/1200/300?random=39",
    bio: "Health Tech Innovators is dedicated to exploring innovative solutions at the intersection of healthcare and technology. Join us to shape the future of health tech!",
  },
  {
    name: "Green Energy Advocates",
    ranking: 10,
    followers: 500,
    hosting_members: ["Sophie Turner", "Mason White", "Grace Davis"],
    Members: 700,
    Creator: "Sophie Turner",
    Membership: "Standard",
    image: "https://picsum.photos/800/400?random=30",
    banner: "https://picsum.photos/1200/300?random=40",
    bio: "Green Energy Advocates is a community passionate about sustainable and green energy solutions. Join us to promote and advocate for a greener future!",
  },
];
export const storiesData = [
  {
    id: 1,
    title: "The Adventure Begins",
    author: "John Doe",
    content: "Once upon a time...",
    publishedDate: "2023-01-01",
    views: 1200,
    profileImage: "https://picsum.photos/200/300/?random=1",
    university: "Fiction University",
    story: "https://picsum.photos/800/600?random=101.jpg",
  },
  {
    id: 2,
    title: "Mystery in the Dark",
    author: "Jane Smith",
    content: "In a small town...",
    publishedDate: "2023-02-15",
    views: 800,
    profileImage: "https://picsum.photos/200/300/?random=2",
    university: "Mystery University",
    story: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
  },
  {
    id: 3,
    title: "Love Beyond Borders",
    author: "Alex Johnson",
    content: "Across the oceans...",
    publishedDate: "2023-03-10",
    views: 1500,
    profileImage: "https://picsum.photos/200/300/?random=3",
    university: "Romance University",
    story: "https://picsum.photos/800/600?random=103.jpg",
  },
  {
    id: 4,
    title: "Tech Titans Clash",
    author: "Eva Williams",
    content: "In the world of technology...",
    publishedDate: "2023-04-20",
    views: 2000,
    profileImage: "https://picsum.photos/200/300/?random=4",
    university: "Tech University",
    story: "https://www.youtube.com/watch?v=3tmd-ClpJxA",
  },
  {
    id: 5,
    title: "The Magical Forest",
    author: "Sam Brown",
    content: "Deep in the enchanted forest...",
    publishedDate: "2023-05-05",
    views: 1800,
    profileImage: "https://picsum.photos/200/300/?random=5",
    university: "Fantasy University",
    story: "https://picsum.photos/800/600?random=105.jpg",
  },
  {
    id: 6,
    title: "Time Traveler's Dilemma",
    author: "Chris Taylor",
    content: "A scientist discovers a time machine...",
    publishedDate: "2023-06-12",
    views: 2500,
    profileImage: "https://picsum.photos/200/300/?random=6",
    university: "Science University",
    story: "https://www.youtube.com/watch?v=oHg5SJYRHA0",
  },
  {
    id: 7,
    title: "Haunted Mansion",
    author: "Olivia White",
    content: "An old mansion with a dark secret...",
    publishedDate: "2023-07-30",
    views: 1300,
    profileImage: "https://picsum.photos/200/300/?random=7",
    university: "Horror University",
    story: "https://picsum.photos/800/600?random=107.jpg",
  },
  {
    id: 8,
    title: "Epic Space Odyssey",
    author: "Max Turner",
    content: "Exploring the vastness of space...",
    publishedDate: "2023-08-18",
    views: 3000,
    profileImage: "https://picsum.photos/200/300/?random=8",
    university: "Space University",
    story: "https://www.youtube.com/watch?v=9bZkp7q19f0",
  },
  {
    id: 9,
    title: "In the Land of Dragons",
    author: "Sophie Green",
    content: "A magical land filled with dragons...",
    publishedDate: "2023-09-25",
    views: 1700,
    profileImage: "https://picsum.photos/200/300/?random=9",
    university: "Fantasy University",
    story: "https://picsum.photos/800/600?random=109.jpg",
  },
  {
    id: 10,
    title: "The Last Samurai",
    author: "Leo Yamamoto",
    content: "A lone warrior's quest for redemption...",
    publishedDate: "2023-10-10",
    views: 2200,
    profileImage: "https://picsum.photos/200/300/?random=10",
    university: "History University",
    story: "https://www.youtube.com/watch?v=cGzLoZQMZwA",
  },
];

export const boostedStories = [
  {
    id: 11,
    title: "Eternal Love",
    author: "Isabella Rodriguez",
    content: "A tale of love that transcends time...",
    publishedDate: "2023-11-05",
    views: 3500,
    profileImage: "https://picsum.photos/200/300/?random=11",
    university: "Romance University",
    isBoosted: true,
    numberOfBoosts: 15,
    boostExpiry: "2024-01-01",
    boostedBy: "PremiumReader123",
    tags: ["Romance", "Eternal Love"],
    story: {
      type: "image",
      content: "https://picsum.photos/800/600/?random=11",
    },
  },
  {
    id: 12,
    title: "Lost in the Cosmos",
    author: "Gabriel Turner",
    content: "Exploring the mysteries of the cosmos...",
    publishedDate: "2023-11-15",
    views: 4200,
    profileImage: "https://picsum.photos/200/300/?random=12",
    university: "Space University",
    isBoosted: true,
    numberOfBoosts: 20,
    boostExpiry: "2024-02-15",
    boostedBy: "StellarReader456",
    tags: ["Space", "Cosmos"],
    story: {
      type: "video",
      content: "https://www.youtube.com/watch?v=example",
    },
  },
  {
    id: 13,
    title: "The Enchanted Elixir",
    author: "Mia Johnson",
    content: "A magical potion with unexpected consequences...",
    publishedDate: "2023-11-25",
    views: 2800,
    profileImage: "https://picsum.photos/200/300/?random=13",
    university: "Fantasy University",
    isBoosted: true,
    numberOfBoosts: 12,
    boostExpiry: "2024-03-10",
    boostedBy: "EnchantingReader789",
    tags: ["Fantasy", "Magic"],
    story: {
      type: "image",
      content: "https://picsum.photos/800/600/?random=13",
    },
  },
  {
    id: 14,
    title: "Beyond the Horizon",
    author: "Lucas Anderson",
    content: "An epic journey to the unknown...",
    publishedDate: "2023-12-05",
    views: 5000,
    profileImage: "https://picsum.photos/200/300/?random=14",
    university: "Adventure University",
    isBoosted: true,
    numberOfBoosts: 18,
    boostExpiry: "2024-04-20",
    boostedBy: "AdventurousReader101",
    tags: ["Adventure", "Journey"],
    story: {
      type: "video",
      content: "https://www.youtube.com/watch?v=example",
    },
  },
  {
    id: 15,
    title: "Whispers in the Wind",
    author: "Aria Thompson",
    content: "A mysterious tale of whispers and secrets...",
    publishedDate: "2023-12-20",
    views: 3800,
    profileImage: "https://picsum.photos/200/300/?random=15",
    university: "Mystery University",
    isBoosted: true,
    numberOfBoosts: 25,
    boostExpiry: "2024-05-15",
    boostedBy: "MysteriousReader007",
    tags: ["Mystery", "Secrets"],
    story: {
      type: "image",
      content: "https://picsum.photos/800/600/?random=15",
    },
  },
  // Additional Records to Make it Exactly 10
  {
    id: 16,
    title: "Ocean's Symphony",
    author: "Natalie Blue",
    content: "The harmonious melody of the ocean...",
    publishedDate: "2024-01-10",
    views: 3000,
    profileImage: "https://picsum.photos/200/300/?random=16",
    university: "Nature University",
    isBoosted: true,
    numberOfBoosts: 10,
    boostExpiry: "2024-06-01",
    boostedBy: "NatureLover456",
    tags: ["Nature", "Ocean"],
    story: {
      type: "image",
      content: "https://picsum.photos/800/600/?random=16",
    },
  },
  {
    id: 17,
    title: "Sunset Serenity",
    author: "Elijah Sky",
    content: "Capturing the tranquility of a sunset...",
    publishedDate: "2024-02-20",
    views: 2500,
    profileImage: "https://picsum.photos/200/300/?random=17",
    university: "Photography University",
    isBoosted: true,
    numberOfBoosts: 8,
    boostExpiry: "2024-07-15",
    boostedBy: "PhotoEnthusiast789",
    tags: ["Photography", "Sunset"],
    story: {
      type: "video",
      content: "https://www.youtube.com/watch?v=example",
    },
  },
  {
    id: 18,
    title: "Silent Snowfall",
    author: "Winter Dreamer",
    content: "The serene beauty of a snowy landscape...",
    publishedDate: "2024-03-15",
    views: 1800,
    profileImage: "https://picsum.photos/200/300/?random=18",
    university: "Winter University",
    isBoosted: true,
    numberOfBoosts: 6,
    boostExpiry: "2024-08-10",
    boostedBy: "Snowflake123",
    tags: ["Winter", "Snowfall"],
    story: {
      type: "image",
      content: "https://picsum.photos/800/600/?random=18",
    },
  },
  {
    id: 19,
    title: "City Lights Euphoria",
    author: "Lila Nightingale",
    content: "Dancing lights in the heart of the city...",
    publishedDate: "2024-04-10",
    views: 4000,
    profileImage: "https://picsum.photos/200/300/?random=19",
    university: "Urban University",
    isBoosted: true,
    numberOfBoosts: 14,
    boostExpiry: "2024-09-25",
    boostedBy: "CityExplorer456",
    tags: ["City", "Lights"],
    story: {
      type: "video",
      content: "https://www.youtube.com/watch?v=example",
    },
  },
  {
    id: 20,
    title: "Firefly Fantasy",
    author: "Eva Starlight",
    content: "A magical night with dancing fireflies...",
    publishedDate: "2024-05-25",
    views: 3200,
    profileImage: "https://picsum.photos/200/300/?random=20",
    university: "Fantasy University",
    isBoosted: true,
    numberOfBoosts: 16,
    boostExpiry: "2024-10-20",
    boostedBy: "MagicEnthusiast789",
    tags: ["Fantasy", "Fireflies"],
    story: {
      type: "image",
      content: "https://picsum.photos/800/600/?random=20",
    },
  },
];

export const userData = [
  {
    id: 1,
    fullname: "Alice Johnson",
    username: "alicej",
    email: "alice.j@email.com",
    phone_number: "+1234567890",
    date_of_birth: "1995-07-22",
    gender: "female",
    password: "hashed_password_123",
    two_factor_authentication: true,
    profile_picture: "https://randomuser.me/api/portraits/women/1.jpg",
    cover_photo: "https://placekitten.com/1200/400",
    bio: "Tech enthusiast and bookworm.",
    interests: ["Technology", "Reading", "Photography"],
    relationship_status: "Single",
    account_visibility: "public",
    post_visibility_settings: "friends only",
    friends: [2, 3],
    pending_friend_requests: [4, 5],
    blocked_users: [8],
    posts: [
      {
        id: 1,
        content: "Excited about my new coding project!",
        type: "simple",
        comments: [
          { user_id: 2, text: "Great work!" },
          { user_id: 3, text: "Can't wait to see it!" },
        ],
        likes: [2, 3],
        shared_content: null,
      },
      {
        id: 2,
        content: "Boosted my post for the coding workshop!",
        type: "boosted",
        comments: [],
        likes: [4, 6],
        shared_content: {
          original_post_id: 1,
          boosted_by: 7,
        },
      },
    ],
    notifications: [
      { id: 1, text: "You have a new friend request from Bob." },
      { id: 2, text: "Your post was liked by Carol." },
      { id: 3, text: "New message from David." },
    ],
    education: {
      university: "Tech University",
      major: "Computer Science",
      graduation_year: 2023,
    },
    location: {
      current: "Cityville, USA",
      hometown: "Smalltown, USA",
    },
    account_status: "active",
    account_creation_date: "2022-02-15",
    last_login_timestamp: "2023-06-10T12:45:00Z",
  },
  {
    id: 2,
    fullname: "Bob Smith",
    username: "bobsmith",
    email: "bob.smith@email.com",
    phone_number: "+9876543210",
    date_of_birth: "1992-05-10",
    gender: "male",
    password: "hashed_password_456",
    two_factor_authentication: false,
    profile_picture: "https://randomuser.me/api/portraits/men/2.jpg",
    cover_photo: "https://placekitten.com/1200/400",
    bio: "Aspiring entrepreneur and fitness enthusiast.",
    interests: ["Business", "Fitness", "Travel"],
    relationship_status: "Single",
    account_visibility: "public",
    post_visibility_settings: "public",
    friends: [1, 3],
    pending_friend_requests: [5, 6],
    blocked_users: [7],
    posts: [
      {
        id: 3,
        content: "Just launched my fitness blog!",
        type: "simple",
        comments: [
          { user_id: 1, text: "Congratulations!" },
          { user_id: 3, text: "Looking forward to your tips!" },
        ],
        likes: [1, 5],
        shared_content: null,
      },
    ],
    notifications: [
      { id: 4, text: "You have a new friend request from Carol." },
      { id: 5, text: "Your post was shared by Alice." },
      { id: 6, text: "New message from Eve." },
    ],
    education: {
      university: "Business School",
      major: "Entrepreneurship",
      graduation_year: 2015,
    },
    location: {
      current: "Metroville, USA",
      hometown: "Villagetown, USA",
    },
    account_status: "active",
    account_creation_date: "2021-09-30",
    last_login_timestamp: "2023-06-12T09:30:00Z",
  },
  {
    id: 3,
    fullname: "Charlie Brown",
    username: "charlieb",
    email: "charlie.b@email.com",
    phone_number: "+1122334455",
    date_of_birth: "1988-12-05",
    gender: "male",
    password: "hashed_password_789",
    two_factor_authentication: true,
    profile_picture: "https://randomuser.me/api/portraits/men/3.jpg",
    cover_photo: "https://placekitten.com/1200/400",
    bio: "Adventurous soul with a passion for technology.",
    interests: ["Adventure", "Technology", "Gaming"],
    relationship_status: "Single",
    account_visibility: "public",
    post_visibility_settings: "public",
    friends: [1, 2],
    pending_friend_requests: [4, 5],
    blocked_users: [7],
    posts: [
      {
        id: 4,
        content: "Exploring the latest gaming trends!",
        type: "simple",
        comments: [
          { user_id: 1, text: "Looks fun!" },
          { user_id: 2, text: "Which game are you playing?" },
        ],
        likes: [1, 3],
        shared_content: null,
      },
    ],
    notifications: [
      { id: 7, text: "You have a new friend request from Eve." },
      { id: 8, text: "Your post was shared by Bob." },
      { id: 9, text: "New message from Alice." },
    ],
    education: {
      university: "Adventure University",
      major: "Technology Exploration",
      graduation_year: 2010,
    },
    location: {
      current: "Adventureland, USA",
      hometown: "Explorationville, USA",
    },
    account_status: "active",
    account_creation_date: "2021-11-18",
    last_login_timestamp: "2023-06-14T14:20:00Z",
  },
  {
    id: 4,
    fullname: "Diana Miller",
    username: "dianam",
    email: "diana.m@email.com",
    phone_number: "+3344556677",
    date_of_birth: "1990-03-18",
    gender: "female",
    password: "hashed_password_987",
    two_factor_authentication: false,
    profile_picture: "https://randomuser.me/api/portraits/women/3.jpg",
    cover_photo: "https://placekitten.com/1200/400",
    bio: "Passionate about art and technology.",
    interests: ["Art", "Technology", "Travel"],
    relationship_status: "Single",
    account_visibility: "public",
    post_visibility_settings: "friends_only",
    friends: [2, 3],
    pending_friend_requests: [1, 5],
    blocked_users: [7],
    posts: [
      {
        id: 5,
        content: "Created a digital artwork today!",
        type: "simple",
        comments: [
          { user_id: 2, text: "Amazing!" },
          { user_id: 3, text: "I love your art style!" },
        ],
        likes: [2, 4],
        shared_content: null,
      },
    ],
    notifications: [
      { id: 10, text: "You have a new friend request from Bob." },
      { id: 11, text: "Your post was liked by Charlie." },
      { id: 12, text: "New message from Alice." },
    ],
    education: {
      university: "Art and Tech Institute",
      major: "Digital Arts",
      graduation_year: 2013,
    },
    location: {
      current: "Artropolis, USA",
      hometown: "Creativityville, USA",
    },
    account_status: "active",
    account_creation_date: "2022-04-25",
    last_login_timestamp: "2023-06-16T10:55:00Z",
  },
  {
    id: 4,
    fullname: "Diana Miller",
    username: "dianam",
    email: "diana.m@email.com",
    phone_number: "+3344556677",
    date_of_birth: "1990-03-18",
    gender: "female",
    password: "hashed_password_987",
    two_factor_authentication: false,
    profile_picture: "https://randomuser.me/api/portraits/women/3.jpg",
    cover_photo: "https://placekitten.com/1200/400",
    bio: "Passionate about art and technology.",
    interests: ["Art", "Technology", "Travel"],
    relationship_status: "Single",
    account_visibility: "public",
    post_visibility_settings: "friends_only",
    friends: [2, 3],
    pending_friend_requests: [1, 5],
    blocked_users: [7],
    posts: [
      {
        id: 5,
        content: "Created a digital artwork today!",
        type: "simple",
        comments: [
          { user_id: 2, text: "Amazing!" },
          { user_id: 3, text: "I love your art style!" },
        ],
        likes: [2, 4],
        shared_content: null,
      },
    ],
    notifications: [
      { id: 10, text: "You have a new friend request from Bob." },
      { id: 11, text: "Your post was liked by Charlie." },
      { id: 12, text: "New message from Alice." },
    ],
    education: {
      university: "Art and Tech Institute",
      major: "Digital Arts",
      graduation_year: 2013,
    },
    location: {
      current: "Artropolis, USA",
      hometown: "Creativityville, USA",
    },
    account_status: "active",
    account_creation_date: "2022-04-25",
    last_login_timestamp: "2023-06-16T10:55:00Z",
  },
  {
    id: 6,
    fullname: "Frank Williams",
    username: "frankw",
    email: "frank.w@email.com",
    phone_number: "+1122334455",
    date_of_birth: "1991-11-15",
    gender: "male",
    password: "hashed_password_321",
    two_factor_authentication: false,
    profile_picture: "https://randomuser.me/api/portraits/men/4.jpg",
    cover_photo: "https://placekitten.com/1200/400",
    bio: "Tech geek and music enthusiast.",
    interests: ["Technology", "Music", "Gaming"],
    relationship_status: "Single",
    account_visibility: "public",
    post_visibility_settings: "friends_only",
    friends: [3, 5],
    pending_friend_requests: [1, 2],
    blocked_users: [4],
    posts: [
      {
        id: 7,
        content: "Trying out the latest gaming console!",
        type: "simple",
        comments: [{ user_id: 3, text: "Which game are you playing?" }],
        likes: [5, 6],
        shared_content: null,
      },
    ],
    notifications: [
      { id: 16, text: "You have a new friend request from Diana." },
      { id: 17, text: "Your post was liked by Eve." },
      { id: 18, text: "New message from Alice." },
    ],
    education: {
      university: "Tech Institute",
      major: "Computer Engineering",
      graduation_year: 2014,
    },
    location: {
      current: "Tech City, USA",
      hometown: "Geekville, USA",
    },
    account_status: "active",
    account_creation_date: "2022-01-05",
    last_login_timestamp: "2023-06-20T11:45:00Z",
  },
  {
    id: 7,
    fullname: "Grace Turner",
    username: "gracet",
    email: "grace.t@email.com",
    phone_number: "+3344556677",
    date_of_birth: "1993-04-20",
    gender: "female",
    password: "hashed_password_789",
    two_factor_authentication: true,
    profile_picture: "https://randomuser.me/api/portraits/women/5.jpg",
    cover_photo: "https://placekitten.com/1200/400",
    bio: "Yoga enthusiast and wellness advocate.",
    interests: ["Yoga", "Wellness", "Travel"],
    relationship_status: "Single",
    account_visibility: "public",
    post_visibility_settings: "public",
    friends: [3, 6],
    pending_friend_requests: [1, 4],
    blocked_users: [5],
    posts: [
      {
        id: 8,
        content: "Morning yoga routine for a fresh start!",
        type: "simple",
        comments: [
          { user_id: 3, text: "Inspiring!" },
          { user_id: 6, text: "I need to start yoga too." },
        ],
        likes: [4, 7],
        shared_content: null,
      },
    ],
    notifications: [
      { id: 19, text: "You have a new friend request from Eve." },
      { id: 20, text: "Your post was liked by Frank." },
      { id: 21, text: "New message from Bob." },
    ],
    education: {
      university: "Wellness University",
      major: "Yoga and Wellness",
      graduation_year: 2017,
    },
    location: {
      current: "Wellness City, USA",
      hometown: "Serenityville, USA",
    },
    account_status: "active",
    account_creation_date: "2022-05-15",
    last_login_timestamp: "2023-06-22T09:15:00Z",
  },
  {
    id: 8,
    fullname: "Harry Lee",
    username: "harryl",
    email: "harry.l@email.com",
    phone_number: "+5566778899",
    date_of_birth: "1989-08-12",
    gender: "male",
    password: "hashed_password_987",
    two_factor_authentication: true,
    profile_picture: "https://randomuser.me/api/portraits/men/5.jpg",
    cover_photo: "https://placekitten.com/1200/400",
    bio: "Avid reader and history enthusiast.",
    interests: ["Books", "History", "Travel"],
    relationship_status: "Single",
    account_visibility: "public",
    post_visibility_settings: "friends_only",
    friends: [4, 7],
    pending_friend_requests: [1, 6],
    blocked_users: [3],
    posts: [
      {
        id: 9,
        content: "Finished a fascinating history book!",
        type: "simple",
        comments: [{ user_id: 4, text: "What book was it?" }],
        likes: [6, 8],
        shared_content: null,
      },
    ],
    notifications: [
      { id: 22, text: "You have a new friend request from Frank." },
      { id: 23, text: "Your post was liked by Grace." },
      { id: 24, text: "New message from Alice." },
    ],
    education: {
      university: "History College",
      major: "Historical Studies",
      graduation_year: 2012,
    },
    location: {
      current: "History City, USA",
      hometown: "Booktown, USA",
    },
    account_status: "active",
    account_creation_date: "2022-02-28",
    last_login_timestamp: "2023-06-24T14:30:00Z",
  },
  {
    id: 9,
    fullname: "Ivy Martinez",
    username: "ivym",
    email: "ivy.m@email.com",
    phone_number: "+7788990011",
    date_of_birth: "1993-01-25",
    gender: "female",
    password: "hashed_password_654",
    two_factor_authentication: false,
    profile_picture: "https://randomuser.me/api/portraits/women/6.jpg",
    cover_photo: "https://placekitten.com/1200/400",
    bio: "Passionate about science and astronomy.",
    interests: ["Science", "Astronomy", "Travel"],
    relationship_status: "Single",
    account_visibility: "public",
    post_visibility_settings: "public",
    friends: [5, 8],
    pending_friend_requests: [1, 7],
    blocked_users: [6],
    posts: [
      {
        id: 10,
        content: "Stargazing tonight with my telescope!",
        type: "simple",
        comments: [
          { user_id: 5, text: "That sounds amazing!" },
          { user_id: 8, text: "Which constellations are you spotting?" },
        ],
        likes: [7, 9],
        shared_content: null,
      },
    ],
    notifications: [
      { id: 25, text: "You have a new friend request from Eve." },
      { id: 26, text: "Your post was liked by Harry." },
      { id: 27, text: "New message from Bob." },
    ],
    education: {
      university: "Science Institute",
      major: "Astrophysics",
      graduation_year: 2015,
    },
    location: {
      current: "Science City, USA",
      hometown: "Stellarville, USA",
    },
    account_status: "active",
    account_creation_date: "2022-06-08",
    last_login_timestamp: "2023-06-26T12:10:00Z",
  },
  {
    id: 10,
    fullname: "Jackie Adams",
    username: "jackiea",
    email: "jackie.a@email.com",
    phone_number: "+1122334455",
    date_of_birth: "1992-07-08",
    gender: "female",
    password: "hashed_password_321",
    two_factor_authentication: true,
    profile_picture: "https://randomuser.me/api/portraits/women/7.jpg",
    cover_photo: "https://placekitten.com/1200/400",
    bio: "Fitness enthusiast and health advocate.",
    interests: ["Fitness", "Health", "Cooking"],
    relationship_status: "Single",
    account_visibility: "public",
    post_visibility_settings: "friends_only",
    friends: [6, 9],
    pending_friend_requests: [1, 8],
    blocked_users: [7],
    posts: [
      {
        id: 11,
        content: "Healthy smoothie recipe for a refreshing day!",
        type: "simple",
        comments: [
          { user_id: 6, text: "I'll try that!" },
          { user_id: 9, text: "Looks delicious and nutritious!" },
        ],
        likes: [8, 10],
        shared_content: null,
      },
    ],
    notifications: [
      { id: 28, text: "You have a new friend request from Grace." },
      { id: 29, text: "Your post was liked by Ivy." },
      { id: 30, text: "New message from Alice." },
    ],
    education: {
      university: "Health and Fitness College",
      major: "Nutrition",
      graduation_year: 2014,
    },
    location: {
      current: "Fitness City, USA",
      hometown: "Wellnessville, USA",
    },
    account_status: "active",
    account_creation_date: "2022-03-12",
    last_login_timestamp: "2023-06-28T08:55:00Z",
  },
];

export const universityData = [
  {
    university_id: 1,
    name: "Bogazici University",
    location: "Istanbul",
    founded_year: 1971,
    website: "https://www.boun.edu.tr/en",
    rank: 1,
    enrolled_students: 25000,
    campus_size: "Urban",
    support_email: "support@boun.edu.tr",
    support_no: "+90 212 359 54 00",
    country: "Turkey",
    state: "Istanbul",
    facebook_page: "https://www.facebook.com/BogaziciUniv/",
    instagram_page: "https://www.instagram.com/bogaziciuniversity/",
    twitter_page: "https://twitter.com/UniBogazici",
    linkedin_page: "https://www.linkedin.com/school/bogazici-university/",
    zip_code: "34342",
    abbreviation: "BU",
    domain: "boun.edu.tr",
    address: "Bebek, 34342 Istanbul, Turkey",
    location_coordinates: { x: 41.0879, y: 29.0462 },
    about_university:
      "Bogazici University is a major research university located in Istanbul, Turkey.",
  },
  {
    university_id: 2,
    name: "Middle East Technical University",
    location: "Ankara",
    founded_year: 1956,
    website: "https://www.metu.edu.tr/",
    rank: 2,
    enrolled_students: 30000,
    campus_size: "Suburban",
    support_email: "support@metu.edu.tr",
    support_no: "+90 312 210 45 00",
    country: "Turkey",
    state: "Ankara",
    facebook_page: "https://www.facebook.com/METU.EDU.TR/",
    instagram_page: "https://www.instagram.com/metuofficial/",
    twitter_page: "https://twitter.com/METU_ODTU",
    linkedin_page:
      "https://www.linkedin.com/school/middle-east-technical-university/",
    zip_code: "06800",
    abbreviation: "METU",
    domain: "metu.edu.tr",
    address: "Inonu Bulvari, 06800 Ankara, Turkey",
    location_coordinates: { x: 39.9334, y: 32.8597 },
    about_university:
      "Middle East Technical University is a leading technical university in Ankara, Turkey.",
  },
  {
    university_id: 3,
    name: "Istanbul Technical University",
    location: "Istanbul",
    founded_year: 1773,
    website: "http://www.itu.edu.tr/en/",
    rank: 3,
    enrolled_students: 28000,
    campus_size: "Urban",
    support_email: "support@itu.edu.tr",
    support_no: "+90 212 293 13 00",
    country: "Turkey",
    state: "Istanbul",
    facebook_page: "https://www.facebook.com/istanbultechnicaluniversity/",
    instagram_page: "https://www.instagram.com/itu1773official/",
    twitter_page: "https://twitter.com/itu1773official",
    linkedin_page:
      "https://www.linkedin.com/school/istanbul-technical-university/",
    zip_code: "34469",
    abbreviation: "ITU",
    domain: "itu.edu.tr",
    address: "Sariyer, 34469 Istanbul, Turkey",
    location_coordinates: { x: 41.1048, y: 29.0241 },
    about_university:
      "Istanbul Technical University is a prominent technical university with a rich history.",
  },
  {
    university_id: 4,
    name: "Hacettepe University",
    location: "Ankara",
    founded_year: 1967,
    website: "http://www.hacettepe.edu.tr/english/",
    rank: 4,
    enrolled_students: 35000,
    campus_size: "Urban",
    support_email: "support@hacettepe.edu.tr",
    support_no: "+90 312 305 50 00",
    country: "Turkey",
    state: "Ankara",
    facebook_page: "https://www.facebook.com/HacettepeUniversitesi/",
    instagram_page: "https://www.instagram.com/hacettepeofficial/",
    twitter_page: "https://twitter.com/hacettepeunv",
    linkedin_page: "https://www.linkedin.com/school/hacettepe-university/",
    zip_code: "06100",
    abbreviation: "HU",
    domain: "hacettepe.edu.tr",
    address: "Sıhhıye, 06100 Ankara, Turkey",
    location_coordinates: { x: 39.9334, y: 32.8597 },
    about_university:
      "Hacettepe University is a leading institution known for its academic excellence.",
  },
  {
    university_id: 5,
    name: "Sabanci University",
    location: "Istanbul",
    founded_year: 1996,
    website: "https://www.sabanciuniv.edu/en/",
    rank: 5,
    enrolled_students: 8000,
    campus_size: "Suburban",
    support_email: "support@sabanciuniv.edu",
    support_no: "+90 216 483 90 00",
    country: "Turkey",
    state: "Istanbul",
    facebook_page: "https://www.facebook.com/SabanciUniv/",
    instagram_page: "https://www.instagram.com/sabanciuniversity/",
    twitter_page: "https://twitter.com/Sabanci_Univ",
    linkedin_page: "https://www.linkedin.com/school/sabanci-university/",
    zip_code: "34956",
    abbreviation: "SU",
    domain: "sabanciuniv.edu",
    address: "Tuzla, 34956 Istanbul, Turkey",
    location_coordinates: { x: 40.8854, y: 29.3782 },
    about_university:
      "Sabanci University is a young and innovative institution dedicated to academic excellence.",
  },
  {
    university_id: 6,
    name: "Koc University",
    location: "Istanbul",
    founded_year: 1993,
    website: "https://www.ku.edu.tr/en/",
    rank: 6,
    enrolled_students: 12000,
    campus_size: "Suburban",
    support_email: "support@ku.edu.tr",
    support_no: "+90 212 338 10 00",
    country: "Turkey",
    state: "Istanbul",
    facebook_page: "https://www.facebook.com/kocuniversity/",
    instagram_page: "https://www.instagram.com/kocuniversity/",
    twitter_page: "https://twitter.com/kocuniversity",
    linkedin_page: "https://www.linkedin.com/school/koc-university/",
    zip_code: "34450",
    abbreviation: "KU",
    domain: "ku.edu.tr",
    address: "Rumelifeneri Yolu, 34450 Istanbul, Turkey",
    location_coordinates: { x: 41.1577, y: 29.0779 },
    about_university:
      "Koc University is a private university known for its high-quality education and research.",
  },
  {
    university_id: 7,
    name: "Ankara University",
    location: "Ankara",
    founded_year: 1946,
    website: "https://www.ankara.edu.tr/en",
    rank: 7,
    enrolled_students: 30000,
    campus_size: "Urban",
    support_email: "support@ankara.edu.tr",
    support_no: "+90 312 595 65 50",
    country: "Turkey",
    state: "Ankara",
    facebook_page: "https://www.facebook.com/AnkaraUniversitesi/",
    instagram_page: "https://www.instagram.com/ankara.university/",
    twitter_page: "https://twitter.com/ankarauniversit",
    linkedin_page: "https://www.linkedin.com/school/ankara-university/",
    zip_code: "06100",
    abbreviation: "AU",
    domain: "ankara.edu.tr",
    address: "Cebeci, 06100 Ankara, Turkey",
    location_coordinates: { x: 39.9334, y: 32.8597 },
    about_university:
      "Ankara University is a prominent public university with a strong academic tradition.",
  },
  {
    university_id: 8,
    name: "Istanbul University",
    location: "Istanbul",
    founded_year: 1453,
    website: "http://www.istanbul.edu.tr/en/",
    rank: 8,
    enrolled_students: 40000,
    campus_size: "Urban",
    support_email: "support@istanbul.edu.tr",
    support_no: "+90 212 440 00 00",
    country: "Turkey",
    state: "Istanbul",
    facebook_page: "https://www.facebook.com/istanbuledutr/",
    instagram_page: "https://www.instagram.com/istanbuledutr/",
    twitter_page: "https://twitter.com/istanbuledutr",
    linkedin_page: "https://www.linkedin.com/school/istanbul-university/",
    zip_code: "34452",
    abbreviation: "IU",
    domain: "istanbul.edu.tr",
    address: "Beyazit, 34452 Istanbul, Turkey",
    location_coordinates: { x: 41.0082, y: 28.9784 },
    about_university:
      "Istanbul University is one of the oldest universities in Turkey, founded in 1453.",
  },
  {
    university_id: 9,
    name: "Ege University",
    location: "Izmir",
    founded_year: 1955,
    website: "http://www.ege.edu.tr/eng/",
    rank: 9,
    enrolled_students: 25000,
    campus_size: "Urban",
    support_email: "support@ege.edu.tr",
    support_no: "+90 232 311 10 10",
    country: "Turkey",
    state: "Izmir",
    facebook_page: "https://www.facebook.com/EgeUniversity/",
    instagram_page: "https://www.instagram.com/egeuniversity/",
    twitter_page: "https://twitter.com/egeuniv",
    linkedin_page: "https://www.linkedin.com/school/ege-universitesi/",
    zip_code: "35100",
    abbreviation: "EU",
    domain: "ege.edu.tr",
    address: "Bornova, 35100 Izmir, Turkey",
    location_coordinates: { x: 38.4237, y: 27.1428 },
    about_university:
      "Ege University is a prominent university located in Izmir, known for its diverse academic programs.",
  },
  {
    university_id: 10,
    name: "Gazi University",
    location: "Ankara",
    founded_year: 1926,
    website: "http://www.gazi.edu.tr/",
    rank: 10,
    enrolled_students: 32000,
    campus_size: "Urban",
    support_email: "support@gazi.edu.tr",
    support_no: "+90 312 202 22 22",
    country: "Turkey",
    state: "Ankara",
    facebook_page: "https://www.facebook.com/gaziuniversitesi/",
    instagram_page: "https://www.instagram.com/gaziuniversitesi/",
    twitter_page: "https://twitter.com/Gazi_Universite",
    linkedin_page: "https://www.linkedin.com/school/gazi-university/",
    zip_code: "06500",
    abbreviation: "GU",
    domain: "gazi.edu.tr",
    address: "Besevler, 06500 Ankara, Turkey",
    location_coordinates: { x: 39.9399, y: 32.8543 },
    about_university:
      "Gazi University is a well-established institution in Ankara, offering a wide range of academic disciplines.",
  },
];

export const turkeyCitiesFilterOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Istanbul",
    value: "Istanbul",
  },
  {
    label: "Ankara",
    value: "Ankara",
  },
  {
    label: "Izmir",
    value: "Izmir",
  },
  {
    label: "Bursa",
    value: "Bursa",
  },
  {
    label: "Antalya",
    value: "Antalya",
  },
  {
    label: "Adana",
    value: "Adana",
  },
  {
    label: "Gaziantep",
    value: "Gaziantep",
  },
  {
    label: "Konya",
    value: "Konya",
  },
  {
    label: "Izmit",
    value: "Izmit",
  },
  {
    label: "Manisa",
    value: "Manisa",
  },
  // Add more cities as needed
];

export const turkeyUniversitiesFilterOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Bogazici University",
    value: "Bogazici University",
  },
  {
    label: "Middle East Technical University",
    value: "Middle East Technical University",
  },
  {
    label: "Istanbul Technical University",
    value: "Istanbul Technical University",
  },
  {
    label: "Hacettepe University",
    value: "Hacettepe University",
  },
  {
    label: "Sabanci University",
    value: "Sabanci University",
  },
  {
    label: "Koc University",
    value: "Koc University",
  },
  {
    label: "Ankara University",
    value: "Ankara University",
  },
  {
    label: "Istanbul University",
    value: "Istanbul University",
  },
  {
    label: "Ege University",
    value: "Ege University",
  },
  {
    label: "Gazi University",
    value: "Gazi University",
  },
];

export const customComunityilterOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Premium",
    value: "Premium",
  },
  {
    label: "Standard",
    value: "Standard",
  },
];
export const eventListfilterOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Public Events",
    value: "Public Events",
  },
  {
    label: "Anonymous Events",
    value: "Anonymous Events",
  },
];
export const postfilterOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Public Posts",
    value: "Public Posts",
  },
  {
    label: "Anonymous Posts",
    value: "Anonymous Posts",
  },
];
export const officialCommunityfilterOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Official Community",
    value: "Official Community",
  },
  {
    label: "Custom Community",
    value: "Custom Community",
  },
];
export const storiesfilterOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Public",
    value: "Public",
  },
  {
    label: "Private",
    value: "Private",
  },
  // {
  //   label: "Tomorrow",
  //   value: "Tomorrow",
  // },
  // {
  //   label: "Yesterday",
  //   value: "Yesterday",
  // },
];

export const boostsOption = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Posts",
    value: "post",
  },
  {
    label: "Stories",
    value: "story",
  },
  {
    label: "Events",
    value: "event",
  },
];
