import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { getPostById } from "../../services/service";
import ListHeader from "../../molecules/ListHeader/ListHeader";
import SinglePost from "../../components/Post/SinglePost";

const SinglePostDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const [post, setPost] = useState({});
  const [linkBreadCrum, setLinkBreadCrum] = useState("/allpost");

  const handlegetPostData = async () => {
    try {
      const response = await getPostById({ id });

      if (response?.success) {
        const newPosts = response?.data;
        setPost(newPosts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (location.state?.from === "/moderation/posts") {
      setLinkBreadCrum("/moderation/posts");
    }
    handlegetPostData();

    // Scroll to the top of the div whenever the post changes or the location changes
  }, [location.state, id]);

  return (
    <div
      className="row px-2 pt-4"

      // Example styles for the div
    >
      <div className="col-12">
        <div className="row d-flex align-items-end">
          <div className="col-12">
            <ListHeader
              mainHeading="Post View"
              placeholder="Search Title"
              btnText=""
              linkbtn=""
              linkBreadCrum={linkBreadCrum}
              blinkBreadCrumText="Posts"
              blinkBreadCrumText1="Post View"
            />
          </div>
        </div>
      </div>
      <div className="col-12 mb-5 pb-5 px-0 pt-4 mt-3">
        <SinglePost post={post} autoFetch={true} />
      </div>
    </div>
  );
};

export default SinglePostDetails;
