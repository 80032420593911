import React from "react";
import "../../assets/css/comment.scss";
import { DeleteIcon } from "../../assets/icons";
import { CMSvg, LikeSvg, TagSvg, notLikedSvg } from "../../assets/icons";

const Comment = ({
  username,
  replyTo,
  time,
  text,
  likes,
  replies,
  profileImage,
}) => {
  return (
    <div className="d-flex px-4 align-items-center">
      <div className="comment">
        <div className="comment__header">
          <img
            src={
              profileImage ? profileImage : "https://via.placeholder.com/150"
            }
            alt="User profile"
            className="profile-pic"
          />
          <div className="comment__user-info pl-2">
            <span className="comment__username">{username}</span>
            <span className="comment__reply-time">{time}</span>
          </div>
        </div>
        <div className="comment__body">
          <span className="comment__replying-to">
            Replying to <a href={`/${replyTo}`}>@{replyTo}</a>
          </span>
          <p className="comment__text">{text}</p>
        </div>
        <div className="post-actions">
          <span className="likes">
            {" "}
            {notLikedSvg} {likes}
          </span>
          <span className="comments">
            {CMSvg} {replies}
          </span>
        </div>
      </div>
      <div className="comment__delete-btn mr-4">{DeleteIcon}</div>
    </div>
  );
};

export default Comment;
