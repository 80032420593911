import React from "react";
import gallery from "../../assets/images/university/gallery.svg";
import camera from "../../assets/images/university/camera.svg";
import dummy from "../../assets/images/university/dummy-profile.svg";
import "./university.scss";

const ImageUpload = ({
  bgImage,
  handleUpload,
  setBgImage,
  profileImage,
  setProfileImage,
  uploadProfile
}) => {
  return (
    <div
      className="cover-image"
      style={{
        backgroundImage: `url(${bgImage?.imageUrl ? bgImage?.imageUrl : gallery})`,
        backgroundSize: bgImage?.imageUrl ? "cover" : "2rem",
        backgroundPosition: bgImage?.imageUrl ? "center" : "center 3rem",
      }}
    >
      <label htmlFor="bg_img">
        <img className="camera-cover cursor-pointer" src={camera} alt="" />
      </label>
      <input
        type="file"
        accept="image/*"
        id="bg_img"
        hidden
        onChange={(e) => handleUpload(e, setBgImage, 'banner_image')}
      />
      {uploadProfile &&
        <div className="profile-image">
          <div className="upload-profile">
            <img
              src={profileImage.imageUrl ? profileImage.imageUrl : dummy}
              alt="dummy-profile"
              className={`${profileImage.imageUrl ? "h-100 w-100" : ""} rounded-circle`}
              style={{ objectFit: profileImage.imageUrl ? "cover" : "contain" }}
            />
            <input
              type="file"
              accept="image/*"
              id="pfp_img"
              hidden
              onChange={(e) => handleUpload(e, setProfileImage, 'profile_image')}
            />
            <label htmlFor="pfp_img">
              <img
                className="profile-camera cursor-pointer"
                src={camera}
                alt=""
              />
            </label>
          </div>
        </div>
      }
    </div>
  );
};




export default ImageUpload;