import React from "react";
import CopyRightIcon from "../../assets/images/dashboard/CopyRightIcon.svg";
import HeartIcon from "../../assets/images/dashboard/HeartIcon.svg";

const Footer = () => {
  return (
    <>
      <div
        className="site-footer d-flex justify-content-center align-items-center pr-5"
        style={{ width: "100%" }}
      >
        <span>
          <img
            style={{ fill: "#FF0000" }}
            className="pb-1"
            src={CopyRightIcon}
            alt=""
          />{" "}
          Mahzen 2024. ALL RIGHTS RESERVED
        </span>
      </div>
    </>
  );
};

export default Footer;
