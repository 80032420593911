import InfiniteScroll from "react-infinite-scroll-component";
import React, { useEffect, useState } from "react";
import ListHeader from "../../molecules/ListHeader/ListHeader";
import { getUserPosts } from "../../services/service";
import "../../assets/css/post.scss";
import SinglePost from "../../components/Post/SinglePost";
import { useParams } from "react-router-dom";
import { Divider, Skeleton } from "antd";

const PostDetail = () => {
  const { id } = useParams();
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const handlegetPostsData = async (id, page, limit) => {
    try {
      const response = await getUserPosts(id, page, limit);

      if (response.success) {
        const newPosts = response.data;
        if (newPosts.length === 0) {
          setHasMore(false);
        } else {
          setPosts((prevPosts) => [...prevPosts, ...newPosts]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handlegetPostsData(id, page, 10);
  }, [id, page]);

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="row px-2 pt-4">
      <div className="col-12">
        <div className="row d-flex align-items-end">
          <div className="col-12">
            <ListHeader
              mainHeading=""
              placeholder="Search user by name, email, university or course"
              btnText=""
              linkbtn=""
              linkBreadCrum="/users"
              blinkBreadCrumText="Users"
              blinkBreadCrumText1="All Posts"
              searchShow={true}
            />
          </div>
        </div>
      </div>
      <div className="col-12 mb-5 pb-5">
        <div
          className="border-top pt-3 remove-x-overflow-doc-list"
          id="scrollableDiv"
          style={{
            width: "100%",
            height: "80vh",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchMoreData}
            hasMore={hasMore}
            // loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
            scrollableTarget="scrollableDiv"
          >
            <div className="row">
              <div className="col-12">
                {posts.map((post, index) => (
                  <SinglePost key={index} post={post} />
                ))}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default PostDetail;
