import React, { useEffect } from "react";
import Slider from "react-slick";
import "../../assets/css/Carousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { CMSvg, LikeSvg, TagSvg, notLikedSvg } from "../../assets/icons";
import Comment from "./Comment";
import { getComments } from "../../services/service";
import dummy from "../../assets/images/university/dummy-profile.svg";

const SinglePost = ({ post, autoFetch }) => {
  const navigate = useNavigate();
  let settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };

  const [comments, setComments] = React.useState([]);
  const [commentClicked, setCommentClicked] = React.useState(
    autoFetch || false
  );
  function formatTimestamp(timestamp) {
    const date = moment(timestamp);
    // Format the day of the week
    // const daysOfWeek = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
    // const day = daysOfWeek[date.day()];
    const relativeTime = date.fromNow();
    const formattedTime = ` · ${relativeTime}`;
    return formattedTime;
  }

  const getPostComments = async () => {
    // API call to get comments

    const response = await getComments(post?._id);
    console.log("response", response);
    if (response && response?.data) {
      setComments(response?.data);
    }
  };

  useEffect(() => {
    if (autoFetch && post?._id) {
      getPostComments();
    }
  }, [autoFetch, post]);

  return (
    <div className="post px-4">
      <div className="post-header">
        <img
          src={post?.user?.profileImage ? post?.user?.profileImage : dummy}
          alt="profile"
          className="profile-pic"
        />
        <div className="post-info">
          <span className="username">{post?.user?.userName}</span>
          <span className="post-time">
            {" "}
            {formatTimestamp(post?.createdAt)}
          </span>{" "}
          <br />
          <span className="post-location">
            Posted on {post?.user?.university?.universityAbbreviation}
          </span>
        </div>
      </div>
      <div className="post-content">
        <p>
          {/* <Link to={`/users/detail/${post?.user?._id}`} className="mention">
            @mehmet_2323
          </Link>{" "} */}
          {post?.description}
        </p>
      </div>
      {(post?.imgurl?.length > 0 || post?.video?.length > 0) && (
        <div className="post-slider">
          <Slider {...settings}>
            {post?.imgurl?.map((img) => (
              <div className="image_container_slider">
                {img?.endsWith(".mp4") ? (
                  <video
                    src={img}
                    alt="slider"
                    className="slider-image"
                    controls
                  />
                ) : (
                  <img src={img} alt="slider" className="slider-image" />
                )}
              </div>
            ))}
            {post?.video?.map((video) => (
              <div className="image_container_slider">
                <video
                  src={video}
                  alt="slider"
                  className="slider-image"
                  controls
                />
              </div>
            ))}
          </Slider>
        </div>
      )}
      <div className="post-footer">
        <div className="post-actions">
          <span className="likes">
            {" "}
            {post?.isLiked ? LikeSvg : notLikedSvg} {post?.likeCounter}
          </span>
          <span
            className="comments"
            onClick={() => {
              setCommentClicked(!commentClicked);
              getPostComments();
            }}
          >
            {CMSvg} {post?.commentCount}
          </span>
          <span className="shares">
            {TagSvg} {post?.taggedPeopleCount}
          </span>
        </div>
      </div>
      <div className="pl-2">
        {commentClicked &&
          comments?.map(
            (comment, index) =>
              (index <= 3 || autoFetch) && (
                <Comment
                  username={comment?.user?.userName}
                  replyTo={post?.user?.userName}
                  time={formatTimestamp(comment?.createdAt)}
                  text={comment?.content}
                  likes={comment?.likeCounter}
                  replies={comment?.replies?.length}
                  profileImage={comment?.user?.profileImage}
                />
              )
          )}
        {!autoFetch && comments?.length > 4 && (
          <span
            className="color-amber-500 font-italic"
            role="button"
            onClick={() => {
              navigate(`/post/detail/${post?._id}`);
            }}
          >
            show more...
          </span>
        )}
      </div>
    </div>
  );
};

export default SinglePost;
